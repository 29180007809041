import React, { useState, useContext, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'primereact/resources/themes/vela-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import './assets/styles/main.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import Login from './pages/Auth/Login'
import Registration from './pages/Auth/Registration'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/ResetPassword'
import PetInformation from './pages/Auth/PetInformation'
import GetStarted from './pages/Auth/GetStarted'
import Home from './pages/Home/Home'
import Header from './sharedComponent/Header'
import DoctorsList from './pages/Doctors/DoctorsList'
import ProfileSetting from './pages/CustomerProfile/ProfileSetting'
import DoctorDetails from './pages/Doctors/DoctorDetails'
import BookAppoitment from './pages/Doctors/BookAppoitment'
import Medicines from './pages/Medicines/Medicines'
import AmbulanceBook from './pages/CustomerProfile/AmbulanceBook'
import ProductDetails from './pages/Medicines/ProductDetails'
import AllSellers from './pages/Medicines/AllSellers'
import MedicinesCart from './pages/Medicines/MedicinesCart'
import MedicineCheckout from './pages/Medicines/MedicineCheckout'
import PetShops from './pages/PetShops/PetShops'
import PetShopDetails from './pages/PetShops/PetShopDetails'
import PetAllSellers from './pages/PetShops/PetAllSellers'
import PetCart from './pages/PetShops/PetCart'
import PetCheckout from './pages/PetShops/PetCheckout'
import SupportDetails from './pages/CustomerProfile/SupportDetails'
import MyOrders from './pages/CustomerProfile/MyOrders'
import MyOrderDetails from './pages/CustomerProfile/MyOrderDetails'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrivateRoutes from './Private Routes/PrivateRoutes'
import AuthRoutes from './Private Routes/AuthRoutes'
import BackDropLoader from './sharedComponent/BackDropLoader'
import { AppContext } from './context/AppContext'
import Chatcomponent from './pages/CustomerProfile/Chatcomponent'
import Notification from './sharedComponent/Notification'
import VaccinatorsList from './pages/Vaccinators/VaccinatorsList.js'
import VaccinatorsAppointment from './pages/Vaccinators/VaccinatorsAppointment.js'
import TrackAmbulance from './pages/CustomerProfile/TrackAmbulance.js'
import TermsAndCondition from './pages/Auth/TermsAndCondition.js'
import PrivacyPolicy from './pages/Auth/PrivacyPolicy.js'
import ReturnAnRefund from './pages/Auth/ReturnAnRefund.js'
import ShippingPolicy from './pages/Auth/ShippingPolicy.js'
import Contact from './pages/Auth/Contact.js'
import PageNotFound from './sharedComponent/PageNotFound.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
function App() {
  const location = useLocation()
  let currentRoute = location.pathname
  const { bkdropLdr } = useContext(AppContext)
  const phoneNumber = '7368012154'; // Replace with your WhatsApp number
  const message = 'Hello Animals App,I have a query, can you please help?.'; // Optional initial message
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  const styles = {
    whatsappButton: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '60px',          // Set the width
      height: '60px',         // Set the height
      backgroundColor: 'green',
      borderRadius: '50%',
      padding: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };
  return (
    <>
      {bkdropLdr && <BackDropLoader />}
      <div style={styles.whatsappButton}>
      <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '2rem', color: 'white' }} />
      </a>
    </div>
      <ToastContainer />
      <div className='screens-wrapper container gx-0'>
        {currentRoute === '/' ||
          currentRoute === '/ForgotPassword' ||
          currentRoute === '/Registration' ||
          currentRoute === '/ResetPassword' ||
          currentRoute === '/PetInformation' ||
          currentRoute === '/GetStarted' ? (
          <></>
        ) : (
          <>
            {' '}
            <Header />
          </>
        )}

        {
          bkdropLdr === true || bkdropLdr === false &&
          <Routes>
            <Route path='TermsAndCondition' element={<TermsAndCondition />} />
            <Route path='PrivacyPolicy' element={<PrivacyPolicy />} />
            <Route path='ReturnAnRefund' element={<ReturnAnRefund />} />
            <Route path='ShippingPolicy' element={<ShippingPolicy />} />
            <Route path='Contact' element={<Contact />} />
          </Routes>
        }
        <Routes>
          {/*=============== Auth Pages ========== */}
          <Route element={<AuthRoutes />}>
            <Route exact path='/' element={<Login />} />
            <Route path='Registration' element={<Registration />} />
            <Route path='ForgotPassword' element={<ForgotPassword />} />
            <Route path='ResetPassword' element={<ResetPassword />} />
            <Route path='GetStarted' element={<GetStarted />} />
            <Route path='PetInformation' element={<PetInformation />} />
          </Route>

          {/*=============== Auth Pages ========== */}

          {/* ========== Home Screens ====== */}

          <Route element={<PrivateRoutes />}>
            <Route path='Home' element={<Home />} />
            <Route path='Notification' element={<Notification />} />
            {/* ========== Doctor's Screens ====== */}
            <Route path='DoctorsList' element={<DoctorsList />} />
            <Route path='DoctorDetails/:id' element={<DoctorDetails />} />
            <Route path='BookAppoitment' element={<BookAppoitment />} />
            {/* ========== Vaccinators's Screens ====== */}
            <Route path='vaccinators' element={<VaccinatorsList />} />
            <Route
              exact
              path='book-vaccinator'
              element={<VaccinatorsAppointment />}
            />
            {/* ========== Vaccinators's Screens ====== */}
            {/* ========== Medicines Screens ====== */}
            <Route path='Medicines' element={<Medicines />} />
            <Route path='ProductDetails' element={<ProductDetails />} />
            <Route path='AllSellers' element={<AllSellers />} />
            <Route path='cart' element={<MedicinesCart />} />
            <Route path='MedicineCheckout' element={<MedicineCheckout />} />
            {/* ========== Vet/Pet Shop Screens ====== */}
            <Route path='PetShops' element={<PetShops />} />
            <Route path='PetShopDetails' element={<PetShopDetails />} />
            <Route path='PetAllSellers' element={<PetAllSellers />} />
            <Route path='PetCart' element={<PetCart />} />
            <Route path='PetCheckout' element={<PetCheckout />} />
            {/* ========== Profile Setting ====== */}
            <Route path='ProfileSetting' element={<ProfileSetting />} />
            <Route path='MyOrders' element={<MyOrders />} />
            <Route path='MyOrderDetails' element={<MyOrderDetails />} />
            <Route path='AmbulanceBook/:id' element={<AmbulanceBook />} />
            <Route path='TrackAmbulance' element={<TrackAmbulance />} />
            <Route path='SupportDetails' element={<SupportDetails />} />
            <Route path='chatcomponent' element={<Chatcomponent />} />
            {/* <Route component={PageNotFound} /> */}
          </Route>
        </Routes>
      
      </div >
    </>
  )
}

export default App
