// ===================================== LOCAL SERVER ====================== //
// export const BASE_URL = "http://192.168.1.56:3000/"; // VINOD PC IP
// ===================================== LOCAL SERVER ====================== //

// ===================================== DEVELOPMENT SERVER (REDBYTE_SERVER) ====================== //

// ===================================== DEVELOPMENT SERVER (REDBYTE_SERVER) ====================== //

// ===================================== PRODUCTION SERVER ====================== //
export const BASE_URL = "https://api.theanimalsapp.com:3000/"
// ===================================== PRODUCTION SERVER ====================== //


const CUSTOMER_PATH = "api/customer/";
const PHARMACY_PATH = "api/pharmacy/";

export const ApiConfig = {
  
  // ========================== AUTHENTICATION ================================= //
  // REGISTRATION
  REGISTRATION: `${BASE_URL}${CUSTOMER_PATH}register`,
  REQUEST_OTP: `${BASE_URL}${CUSTOMER_PATH}sendOTP`,
  VARIFY_OTP: `${BASE_URL}${CUSTOMER_PATH}verifyOTP`,
  // REGISTRATION

  // FORGOT PASSWORD
  FORGOT_PASSWORD: `${BASE_URL}${CUSTOMER_PATH}forgotPassword`,
  RESET_PASSWORD: `${BASE_URL}${CUSTOMER_PATH}resetPassword`,
  // FORGOT PASSWORD

  // LOGIN
  CUSTOMER_LOGIN: `${BASE_URL}${CUSTOMER_PATH}login`,
  // ------LOGIN WITH OTP----------
  SEND_OTP_FOR_LOGIN: `${BASE_URL}${CUSTOMER_PATH}sendOTPForLoginWithOTP`,
  LOGIN_WITH_OTP: `${BASE_URL}${CUSTOMER_PATH}loginWithOTP`,
  // ------LOGIN WITH OTP----------
  // LOGIN
  // ========================== AUTHENTICATION ================================= //

  // ========================== PETINFORMATION ================================= //
  GET_PET_TYPES: `${BASE_URL}${CUSTOMER_PATH}animalTypes`,
  ADD_PET_INFORMATION: `${BASE_URL}${CUSTOMER_PATH}addAnimal`,
  // ========================== PETINFORMATION ================================= //

  // HEADER
  SEARCH: `${BASE_URL}${CUSTOMER_PATH}search`,

  // ========================== DASHBOARD (HOME) ================================= //
  TOP_DOCTORS_IN_CITIES: `${BASE_URL}${CUSTOMER_PATH}topCityDoctors`,
  TOP_SELLING_MEDICINES: `${BASE_URL}${CUSTOMER_PATH}topSellingMedicines`,
  RECENT_ARTICLES: `${BASE_URL}${CUSTOMER_PATH}recentArticles`,
  CATEGORIES_COUNT: `${BASE_URL}${CUSTOMER_PATH}categoriesCount`,
  TOP_SELLING_PRODUCTS: `${BASE_URL}${CUSTOMER_PATH}topSellingPetProducts`,
  // ========================== DASHBOARD (HOME) ================================= //

  // ========================== CUSTOMER PROFILE ===================================== //
  UPDATE_CUSTOMER_DETAILS: `${BASE_URL}${CUSTOMER_PATH}my-profile-update`,
  UPDATE_PROFILE_IMAGE: `${BASE_URL}${CUSTOMER_PATH}update-profile-image`,
  GET_CUSTOMER_DETAILS: `${BASE_URL}${CUSTOMER_PATH}my-profile`,
  SEND_OTP: `${BASE_URL}${CUSTOMER_PATH}my-profile-send-otp`,
  UPDATE_PASSWORD_SETTINGS: `${BASE_URL}${CUSTOMER_PATH}my-profile-update-password`,
  VERIFY_OTP: `${BASE_URL}${CUSTOMER_PATH}my-profile-verify-otp`,

  // ========================== CUSTOMER PROFILE ===================================== //

  // ========================== ANIMAL PROFILE ===================================== //
  GET_ANIMAL_DETAILS: `${BASE_URL}${CUSTOMER_PATH}my-animals`,
  UPDATE_ANIMAL_PROFILE: `${BASE_URL}${CUSTOMER_PATH}my-animals-update`,
  // ========================== ANIMAL PROFILE ===================================== //

  // ========================== MY APPOINTMENTS ===================================== //
  FETCH_APPOINTMENTS: `${BASE_URL}${CUSTOMER_PATH}getVeterinaryAppointmentList`,
  VIEW_APPOINTMENT: `${BASE_URL}${CUSTOMER_PATH}getVeterinaryAppointmentDetails`,
  CREATE_REVIEW: `${BASE_URL}${CUSTOMER_PATH}my-appointment/create-review`,
  FETCH_APPOINTMENTS_REVIEWS: `${BASE_URL}${CUSTOMER_PATH}my-appointment-review`,
  FETCH_PRESCRIPTION_DATA: `${BASE_URL}${CUSTOMER_PATH}my-appointment-invoice-prescription`,
  FETCH_VACCINES_DATA: `${BASE_URL}${CUSTOMER_PATH}my-vaccine-priscription`,
  FETCH_PETFOOD_DATA: `${BASE_URL}${CUSTOMER_PATH}animal-food-prescription`,
  ORDER_PETFOOD: `${BASE_URL}${CUSTOMER_PATH}food-order-now`,
  BOOK_VACCINATOR_APPOINTMENT: `${BASE_URL}${CUSTOMER_PATH}book-vaccinator`,
  FETCH_VACCINATORS: `${BASE_URL}${CUSTOMER_PATH}vaccinators`,
  // vaccinators
  ORDER_NOW: `${BASE_URL}${CUSTOMER_PATH}order-now`,
  // ========================== MY APPOINTMENTS ===================================== //

  // ========================== MY ORDERS ===================================== //
  FETCH_ORDERS: `${BASE_URL}${CUSTOMER_PATH}my-orders`,
  CREATE_ORDER_REVIEW: `${BASE_URL}${CUSTOMER_PATH}my-order/create-review`,
  MY_ORDER_PRESCRIPTION: `${BASE_URL}${CUSTOMER_PATH}my-order-invoice-prescription`,
  TRACK_ORDER: `${BASE_URL}${CUSTOMER_PATH}my-order-tracking`,
  // ========================== MY ORDERS ===================================== //

  // ========================== DOCTORS APIs ===================================== //
  GET_DOCTORS_SPECIALIST: `${BASE_URL}${CUSTOMER_PATH}DocDropdowns`,
  GET_ALL_DOCTORS: `${BASE_URL}${CUSTOMER_PATH}getDoctorList`,
  GET_DOCTOR_INFO: `${BASE_URL}${CUSTOMER_PATH}DocInfo`,
  ADD_FAVOURITE_DOCTOR: `${BASE_URL}${CUSTOMER_PATH}AddFavDoc`,
  GET_AVAILABLE_SLOTS: `${BASE_URL}${CUSTOMER_PATH}DocDropdowns`,
  DOCTORS_AVAILABLE_SLOTS: `${BASE_URL}${CUSTOMER_PATH}getDoctorLookupData`,
  BOOK_APPOINTMENT: `${BASE_URL}${CUSTOMER_PATH}bookDoctorAppointment`,
  // ========================== DOCTORS APIs ===================================== //

  // ========================== MEDICINE APIs ===================================== //
  MEDICINE_LIST: `${BASE_URL}${CUSTOMER_PATH}medicine-filter`,
  GET_FILTERED_DATA: `${BASE_URL}${CUSTOMER_PATH}medicine-dropdown`,
  MEDICINE_DETAILS: `${BASE_URL}${CUSTOMER_PATH}medicine-view`,
  MEDICINE_SELLER_LIST: `${BASE_URL}${CUSTOMER_PATH}seller-list`,
  GET_RECOMMENDED_LIST: `${BASE_URL}${CUSTOMER_PATH}recommended-medicine-list`,
  ADD_TO_CART: `${BASE_URL}${CUSTOMER_PATH}add-to-cart`,
  FETCH_CART_LIST: `${BASE_URL}${CUSTOMER_PATH}my-cart-list`,
  REMOVE_MEDICINE_FROM_CART: `${BASE_URL}${CUSTOMER_PATH}remove-item`,
  FETCH_BILLING_DETAILS: `${BASE_URL}${CUSTOMER_PATH}billing-details`,
  RECENT_VIEWD_MEDICINES: `${BASE_URL}${CUSTOMER_PATH}recent-viewed`,
  ADD_MEDICINE_TO_FAVOURITE: `${BASE_URL}${CUSTOMER_PATH}add-favourite`,
  PLACE_ORDER: `${BASE_URL}${CUSTOMER_PATH}create-order`,
  FETCH_MY_ANIMALS: `${BASE_URL}${CUSTOMER_PATH}my-animal-name`,
  FETCH_ALL_COUPONS: `${BASE_URL}${CUSTOMER_PATH}discounts`,
  APPLY_COUPONS: `${BASE_URL}${CUSTOMER_PATH}applyCoupons`,
  // ========================== MEDICINE APIs ===================================== //

  // =========================== AMBULANCE ======================================== //
  FETCH_AMBULANCE_LIST: `${BASE_URL}${CUSTOMER_PATH}ambulance-list`,
  FETCH_COMPLETED_AMBULANCE_LIST: `${BASE_URL}${CUSTOMER_PATH}completed-trips`,
  BOOK_AMBULANCE: `${BASE_URL}${CUSTOMER_PATH}book-ambulance`,
  AMBULANCE_RATINGS_AND_DETAILS: `${BASE_URL}${CUSTOMER_PATH}ambulance-rating-list`,
  ANIMAL_LIST: `${BASE_URL}${CUSTOMER_PATH}my-animal-name`,
  TRACKING_DETAILS: `${BASE_URL}${CUSTOMER_PATH}completed-trips`,
  ADD_AMBULANCE_RATING: `${BASE_URL}${CUSTOMER_PATH}add-rating-ambulance`,
  // =========================== AMBULANCE ======================================== //

  // =========================== NOTIFICATIONS ======================================== //
  FETCH_NOTIFICATION_LIST: `${BASE_URL}${PHARMACY_PATH}getNotification`,
  READ_NOTIFICATION: `${BASE_URL}${PHARMACY_PATH}notificationReadStatus`,
  // =========================== NOTIFICATIONS ======================================== //

  // ========================== PET SHOP APIs ===================================== //
  FETCH_PET_SHOP_FILTER: `${BASE_URL}${CUSTOMER_PATH}petshop-dropdown`,
  FETCH_PET_SHOP_LIST: `${BASE_URL}${CUSTOMER_PATH}pet-products`,
  PET_SHOP_PRODUCT_DETAILS: `${BASE_URL}${CUSTOMER_PATH}get-single-products`,
  ADD_TO_PET_FAVORITE: `${BASE_URL}${CUSTOMER_PATH}just-like`,
  PET_SHOP_SELLER_LIST: `${BASE_URL}${CUSTOMER_PATH}pet-seller-list`,
  // ========================== PET SHOP APIs ===================================== //

  // ========================== TRANSACTION APIS ================================== //
  FETCH_TRANSACTION_API: `${BASE_URL}${CUSTOMER_PATH}my-transections-list`,
  // ========================== TRANSACTION APIS ================================== //
  VACCINATORS_APPOINTMENT_LIST: `${BASE_URL}${CUSTOMER_PATH}vaccine-appointment`,
  VACCINATORS_APPOINTMENT_DETAILS: `${BASE_URL}${CUSTOMER_PATH}vaccine-appointment-details`,
  // ========================== SUPPORT ================================== //
  FETCH_SUPPORT_LIST: `${BASE_URL}${CUSTOMER_PATH}support-list`,
  CREATE_SUPPORT: `${BASE_URL}${CUSTOMER_PATH}support-create`,
  // ========================== SUPPORT ================================== //

  ////////////////////// Chat ///////////////////
  CHAT_HISTRY: `${BASE_URL}api/chat/chat-conversion`,
};
