import React, { useContext, useEffect, useState } from "react";
import Header from "../../sharedComponent/Header";
import { Carousel, Modal } from "react-bootstrap";
import bg_slid_lightGreen from "../../assets/images/bg_slid_lightGreen.png";
import bg_slide_blue from "../../assets/images/bg_slide_blue.png";
import bg_slide_green from "../../assets/images/bg_slide_green.png";
import dog_flying from "../../assets/images/dog_flying.png";
import doctor from "../../assets/images/doctor.png";
import medicine from "../../assets/images/medicine.png";
import dummydoctor from "../../assets/images/dummydoctor.jpg";
import dummyMedicine from "../../assets/images/dummyMedicine.jpeg";
import dummymedicene from "../../assets/images/dummymedicene.jpg";
import ic_heart_blank from "../../assets/images/ic_heart_blank.svg";
import ic_arrow_right_green from "../../assets/images/ic_arrow_right_green.svg";
import ic_arrow_right_white from "../../assets/images/ic_arrow_right_white.svg";
import close from "../../assets/images/icons/close.svg";
import ic_added_item from "../../assets/images/ic_added_item.svg";
import ic_heart_filled from "../../assets/images/ic_heart_filled.svg";
import ic_right_arrow_circle from "../../assets/images/ic_right_arrow_circle.svg";
import ic_whatsapp from "../../assets/images/ic_whatsapp.svg";
import ic_rating_start from "../../assets/images/ic_rating_start.svg";
import ic_bag_light from "../../assets/images/ic_bag_light.svg";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useReduxStore } from "../../customHooks/useReduxStore";
import {
  PostCallWithAuthToken,
  PutCallWithAuthToken,
  simpleGetCallWithAuthToken,
} from "../../api/ApiServices";
import { ApiConfig } from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { useDateFormats } from "../../customHooks/useDateFormats";
import PopupOverlay from "./PopupOverlay";
import { notifyError, notifySuccess } from "../../ToastMessages/notify";
import { increaseCartCount } from "../../redux/Slices/cartSlice";
import useSound from "use-sound";
import { useCurrentLatLng } from "../../customHooks/useCurrentLatLng";
import playstore from "../../assets/googleimages/playstore.png";
import { Form } from "react-bootstrap";
import { updateLoginData } from "../../redux/Slices/authSlice";
import banner1 from "../../assets/Banner_Images/Banner1.jpg"
import banner2 from "../../assets/Banner_Images/Banner2.jpg"
import banner3 from "../../assets/Banner_Images/Banner3.jpg"

const Home = () => {
  // DATA FROM CONTEXT
  const {
    drocterCallOpen,
    setBkdropLdr,
    navigate,
    dispatch,
    setDoctorListPayLoad,
    doctorListPayLoad,
    setMedcinePayLoad,
    medcinePayLoad,
    setPetShopPayLoad,
    petShopPayLoad,
    validNumber,
    setEventKey
  } = useContext(AppContext);
  // DATA FROM CONTEXT

  // DATA FROM CUSTOME HOOKS
  const { dateAndMonthOnly } = useDateFormats();
  // DATA FROM CUSTOME HOOKS

  const topScroll = () => {
    window.scrollTo(0, 0);
  };
  // DATA FROM REDUX STORE
  const {
    cartItemCount,
    customer_id,
    customerLoggedInData,
    loginData,
    customer_address,
    customer_animal,
    customer_details,
  } = useReduxStore();
  // DATA FROM REDUX STORE
  const { latitude, longitude } = useCurrentLatLng();
  useEffect(() => {
    setDoctorListPayLoad({
      ...doctorListPayLoad,
      lat: latitude,
      lng: longitude,
      customer_id: customer_id,
    });
    setMedcinePayLoad({
      ...medcinePayLoad,
      lat: latitude,
      lng: longitude,
      customer_id: customer_id,
    });
    setPetShopPayLoad({
      ...petShopPayLoad,
      lat: latitude,
      lng: longitude,
      customer_id: customer_id,
    });
  }, [latitude, longitude]);

  useEffect(() => {
    fectchTopDoctorsInCities();
    if (!customer_details) {
      setOpenModal(true);
    }
    fetchCustomerDetails();
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const [topDoctorsInCity, setTopDoctorsInCity] = useState([]);
  const [topSellingMedicines, setTopSellingMedicines] = useState([]);
  const [recentArticles, setRecentArticles] = useState([]);
  const [categories, setCategories] = useState({});
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const isCategoriesEmpty = Object.keys(categories).length > 0;
  const [isValidated, setIsValidated] = useState(false);
  const [validated, setValidated] = useState(false);

  // topCityDoctors and topSellingMedicines AND recentArticles AND categoriesCount
  const fectchTopDoctorsInCities = async () => {
    const topCityDoctors = simpleGetCallWithAuthToken(
      `${ApiConfig.TOP_DOCTORS_IN_CITIES}?city=aurangabad&&customer_id=${customer_id}`
    );
    const topSellingMedicine = simpleGetCallWithAuthToken(
      `${ApiConfig.TOP_SELLING_MEDICINES}/${customer_id}`
    );
    const recentArticles = simpleGetCallWithAuthToken(
      ApiConfig.RECENT_ARTICLES
    );
    const categoriesCount = simpleGetCallWithAuthToken(
      ApiConfig.CATEGORIES_COUNT
    );
    const topSellingProducts = simpleGetCallWithAuthToken(
      `${ApiConfig.TOP_SELLING_PRODUCTS}/${customer_id}`
    );

    try {
      setBkdropLdr(true);
      const responses = await Promise.all([
        topCityDoctors,
        topSellingMedicine,
        recentArticles,
        categoriesCount,
        topSellingProducts,
      ]);
      const [
        topDoctorsRes,
        topMedicinesRes,
        recentArticleRes,
        categoriesCountRes,
        topSellingProductsRes,
      ] = await Promise.all(responses.map((response) => response.json));
      if (topDoctorsRes?.success) {
        topDoctorsRes?.data && setTopDoctorsInCity(topDoctorsRes?.data);
      }
      if (topMedicinesRes?.success) {
        topMedicinesRes?.data && setTopSellingMedicines(topMedicinesRes?.data);
      }
      if (recentArticleRes?.success) {
        recentArticleRes?.data && setRecentArticles(recentArticleRes?.data);
      }
      if (categoriesCountRes?.success) {
        categoriesCountRes?.data && setCategories(categoriesCountRes?.data);
      }
      if (topSellingProductsRes?.success) {
        topSellingProductsRes?.data &&
          setTopSellingProducts(topSellingProductsRes?.data);
      }
    } catch (error) {
      console.log("Catch error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const addToBagCart = async (
    product_id,
    product_rate,
    shop_id,
    entity_type_id
  ) => {
    const cartPayLoad = {
      customer_id: customer_id,
      product_id: product_id,
      product_quantity: 1,
      product_rate: product_rate,
      shop_id: shop_id,
      entity_type_id: entity_type_id,
    };
    try {
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_CART,
        cartPayLoad
      );
      const { result, message } = res?.json;
      if (result) {
        dispatch(increaseCartCount(cartItemCount + 1));
        const message =
          entity_type_id === 5
            ? "Product added to cart successfully"
            : "Medicine Added to cart successfully";
        notifySuccess(message);
        const tempArr = topSellingMedicines.map((item, i) => {
          return item?.product_id === product_id
            ? { ...item, customer_card_id: true }
            : item;
        });
        setTopSellingMedicines(tempArr);

        const tempArrForPetProducts = topSellingProducts.map((item, i) => {
          return item?.product_id === product_id
            ? { ...item, customer_card_id: true }
            : item;
        });
        setTopSellingProducts(tempArrForPetProducts);
      }
      // else {
      //   notifyError('This product already added to your cart')
      // }
    } catch (error) {
      console.log("catch error:", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // MEDICINES (PHARMACY) FAVORITE API
  const addToFavourite = async (
    product_id,
    entity_type_id,
    actionType,
    category
  ) => {
    try {
      // setLdr(true)
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id,
        entity_type_id: entity_type_id,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_MEDICINE_TO_FAVOURITE,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        if (actionType === "like") {
          const tempArr = topSellingMedicines.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setTopSellingMedicines(tempArr);
        } else if (actionType === "dislike") {
          const tempArr = topSellingMedicines.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setTopSellingMedicines(tempArr);
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  const addPetFoodToFavourite = async (
    product_id,
    entity_type_id,
    actionType
  ) => {
    try {
      setBkdropLdr(true);
      const payLoad = {
        customer_id: customer_id,
        product_id: product_id,
        entity_type_id: entity_type_id,
      };
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_TO_PET_FAVORITE,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        if (actionType === "like") {
          const tempArr = topSellingProducts.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setTopSellingProducts(tempArr);
        } else if (actionType === "dislike") {
          const tempArr = topSellingProducts.map((item, i) => {
            return item?.product_id === product_id
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setTopSellingProducts(tempArr);
        }
      }
    } catch (error) {
      console.log("catch Error:,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  // DOCTOR FAVORITE API
  const addFavourite = async (doctorId, action) => {
    try {
      const payLoad = {
        customer_id: customer_id,
        doctor_id: doctorId,
      };
      setBkdropLdr(true);
      const res = await PostCallWithAuthToken(
        ApiConfig.ADD_FAVOURITE_DOCTOR,
        payLoad
      );
      const { result, message } = res?.json;
      if (result) {
        // notifySuccess('Added to favourites list')
        if (action === "like") {
          const tempArr = topDoctorsInCity.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: true }
              : item;
          });
          setTopDoctorsInCity(tempArr);
        } else {
          const tempArr = topDoctorsInCity.map((item, i) => {
            return item?.doctor_id === doctorId
              ? { ...item, customer_favourite_id: false }
              : item;
          });
          setTopDoctorsInCity(tempArr);
        }
      }
      // else {
      //   notifyError('Already added into favourites list')
      // }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };
  const [updateCustomerPayload, setUpdateCustomerPayload] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: [],
  });

  const passwordHandlerr = (event, index) => {
    const { value, name } = event.target;
    setUpdateCustomerPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateCustomerProfile = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      try {
        setBkdropLdr(true);
        const res = await PutCallWithAuthToken(
          ApiConfig.UPDATE_CUSTOMER_DETAILS,
          updateCustomerPayload
        );
        const { success, data } = res?.json;
        if (success) {
          notifySuccess("Profile details updated successfully");
          dispatch(
            updateLoginData({
              ...loginData,
              customer_details: 1,
              first_name: updateCustomerPayload?.first_name,
              last_name: updateCustomerPayload?.last_name,
            })
          );
          setOpenModal(false);
        }
      } catch (error) {
        console.log("Catch error update customer profile:", error);
      } finally {
        setBkdropLdr(false);
      }
    }
  };

  const fetchCustomerDetails = async () => {
    try {
      setBkdropLdr(true);
      const res = await simpleGetCallWithAuthToken(
        `${ApiConfig.GET_CUSTOMER_DETAILS}/${customer_id}`
      );
      const { success, data } = res?.json;
      if (success) {
        const resData = data[0];
        const tempAddressArr =
          Array.isArray(resData?.address) && resData
            ? resData?.address.map((item, i) => {
                return {
                  ...item,
                  address_type_id: item?.address_type?.address_type_id,
                };
              })
            : [];

        data &&
          setUpdateCustomerPayload({
            ...updateCustomerPayload,
            first_name: resData?.first_name,
            last_name: resData?.last_name,
            email: resData?.email,
            phone_number: resData?.phone_number,
            address: tempAddressArr,
            customer_id: customer_id,
          });
      }
    } catch (error) {
      console.log("error,", error);
    } finally {
      setBkdropLdr(false);
    }
  };

  return (
    <>
      <div className="main-home-wrapper" id="cx-main">
        <div className="Carousel-wrapper">
          {/* {drocterCallOpen === true ? <PopupOverlay /> : <></>} */}

          <Carousel>
            <Carousel.Item>
              <div className="caro-slide-wrapper">
                <Link to="#">
                  <img 
                  // src={bg_slide_green} 
                  src={banner1}
                  alt="" />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="caro-slide-wrapper">
                <Link to="#">
                  <img 
                  // src={bg_slide_blue} 
                  src={banner2}
                  alt="" />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="caro-slide-wrapper">
                <Link to="#">
                  <img 
                  // src={bg_slid_lightGreen}
                  src={banner3}
                  alt="" />
                </Link>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="main-cards-wrapper">
          <section className="Categories-wrapper">
            <h5>Browse by Categories</h5>
            <div className="Categories-cards-wrapper row">
              <div className="col-md-4">
                <div className="Categories-card">
                  <div className="left">
                    <div className="top">
                      <label htmlFor="">Doctors</label>
                      {isCategoriesEmpty && (
                        <span>
                          {categories?.doctor_count >= 200
                            ? `200+`
                            : categories?.doctor_count}
                        </span>
                      )}
                    </div>
                    <Link to="/DoctorsList">
                      <div className="bottom">
                        <button type="button">
                          Book Now <img src={ic_right_arrow_circle} alt="" />
                        </button>
                      </div>
                    </Link>
                  </div>
                  <div className="right">
                    <img src={doctor} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Categories-card petShop">
                  <div className="left">
                    <div className="top">
                      <label htmlFor="">Animal Foods and Products</label>
                      {/* <span>
                        {categories?.pet_shop_count >= 200
                          ? `200+`
                          : categories?.pet_shop_count}
                      </span> */}
                    </div>
                    <div className="bottom">
                      <button
                        onClick={() => {
                          navigate("/PetShops");
                        }}
                      >
                        Shop Now <img src={ic_right_arrow_circle} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="right">
                    <img src={dog_flying} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Categories-card pharma">
                  <div className="left">
                    <div className="top">
                      <label htmlFor="">Pharmacy</label>
                      {/* <span>
                        {categories?.pharmacy_shop_count >= 200
                          ? `200+`
                          : categories?.pharmacy_shop_count}
                      </span> */}
                    </div>
                    <Link to="/Medicines">
                      <div className="bottom">
                        <button>
                          Order Now <img src={ic_right_arrow_circle} alt="" />
                        </button>
                      </div>
                    </Link>
                  </div>
                  <div className="right">
                    <img src={medicine} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {topDoctorsInCity?.length > 0 ? (
            <section className="top-doctors-wrapper">
              <div className="heading-view-all">
                <h5>Top Rated Doctors</h5>
                {/* <h5>Currently Available Doctor</h5> */}
                <Link to="/DoctorsList" className="view-all">
                  View All <img src={ic_arrow_right_green} alt="" />
                </Link>
              </div>

              <div className="doctors-card-wrapper row">
                {topDoctorsInCity.map((doc, idx) => {
                  return (
                    <div className="col-md-3" key={doc?.doctor_id}>
                      <div className="doctors-card">
                        <div className="img-section">
                          <span className="heart">
                            {doc?.customer_favourite_id ? (
                              <img
                                src={ic_heart_filled}
                                alt=""
                                onClick={() => {
                                  // setlike1(!like1)
                                  addFavourite(doc?.doctor_id, "dislike");
                                }}
                              />
                            ) : (
                              <img
                                src={ic_heart_blank}
                                alt=""
                                onClick={() => {
                                  // setlike1(!like1)
                                  addFavourite(doc?.doctor_id, "like");
                                }}
                              />
                            )}
                          </span>
                          {/* dummy-doctor */}
                          <div className="mainImgWrapper">
                            <img
                              src={
                                doc?.doctor_profile_image_path
                                  ? doc?.doctor_profile_image_path
                                  : dummydoctor
                                //  dr_1
                              }
                              alt="Not found"
                              className="img-dr"
                            />
                          </div>
                          {/* <Link
                            to={`/BookAppoitment?doctorId=${doc?.doctor_id}&clinic_id=${doc?.doctor_clinic_id}`}
                          > */}
                            <button className="book-btn"
                            onClick={() => {

                              if (
                                !customer_address ||
                                !customer_details
                              ) {
                                navigate("/ProfileSetting");
                                setEventKey("11");
                                if(!customer_details){
                                  notifyError(
                                    "Please update your profile details and address"
                                  );
                                } else if (!customer_address) {
                                  notifyError(
                                    "Please add your address"
                                  );
                                }
                                sessionStorage.setItem(
                                  "navigationDetails",
                                  JSON.stringify({
                                    isNavigateFromDoctorPage: true,
                                    doctor_id: doc?.doctor_id,
                                    clinic_id: doc?.doctor_clinic_id,
                                  })
                                )
                                }else {
                                  navigate(
                                    `/BookAppoitment?doctorId=${doc?.doctor_id}&clinic_id=${doc?.doctor_clinic_id}`
                                  )
                                }

                            }}
                            >
                              Book Now{" "}
                              <img src={ic_right_arrow_circle} alt="" />
                            </button>
                          {/* </Link> */}
                        </div>

                        <div className="name-rating">
                          <div className="wrapper">
                            <div className="left">
                              <label htmlFor="" className="name">
                                Dr. {doc?.doctor_name}
                              </label>
                            </div>
                            {doc?.doctor_ratings && (
                              <div className="right">
                                <span className="name">
                                  {doc?.doctor_ratings}
                                </span>
                                <img src={ic_rating_start} alt="" />
                              </div>
                            )}
                          </div>
                          <span className="degree">
                            {doc?.specializations[0]
                              ? doc?.specializations[0]
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className='col-md-3'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like2 ? (
                          <img
                            src={ic_heart_filled}
                            alt=''
                            onClick={() => setlike2(!like2)}
                          />
                        ) : (
                          <img
                            src={ic_heart_blank}
                            alt=''
                            onClick={() => setlike2(!like2)}
                          />
                        )}
                      </span>
                      <img src={dr_2} alt='' className='img-dr' />
                      <button className='book-btn'>
                        Book Now <img src={ic_right_arrow_circle} alt='' />
                      </button>
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <label htmlFor='' className='name'>
                            Dr. Floyd Miles
                          </label>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='degree'>Orthopedic</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like3 ? (
                          <img
                            src={ic_heart_filled}
                            alt=''
                            onClick={() => setlike3(!like3)}
                          />
                        ) : (
                          <img
                            src={ic_heart_blank}
                            alt=''
                            onClick={() => setlike3(!like3)}
                          />
                        )}{' '}
                      </span>
                      <img src={dr_1} alt='' className='img-dr' />
                      <button className='book-btn'>
                        Book Now <img src={ic_right_arrow_circle} alt='' />
                      </button>
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <label htmlFor='' className='name'>
                            Dr. Floyd Miles
                          </label>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='degree'>Orthopedic</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='doctors-card'>
                    <div className='img-section'>
                      <span className='heart'>
                        {like4 ? (
                          <img
                            src={ic_heart_filled}
                            alt=''
                            onClick={() => setlike4(!like4)}
                          />
                        ) : (
                          <img
                            src={ic_heart_blank}
                            alt=''
                            onClick={() => setlike4(!like4)}
                          />
                        )}{' '}
                      </span>
                      <img src={dr_2} alt='' className='img-dr' />
                      <button className='book-btn'>
                        Book Now <img src={ic_right_arrow_circle} alt='' />
                      </button>
                    </div>

                    <div className='name-rating'>
                      <div className='wrapper'>
                        <div className='left'>
                          <label htmlFor='' className='name'>
                            Dr. Floyd Miles
                          </label>
                        </div>
                        <div className='right'>
                          <span className='name'>4.3</span>
                          <img src={ic_rating_start} alt='' />
                        </div>
                      </div>
                      <span className='degree'>Orthopedic</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          ) : null}
        </div>
        <div className="main-cards-wrapper" id="top-selling-wrapper">
          {topSellingMedicines && topSellingMedicines.length > 0 ? (
            <section className="top-doctors-wrapper">
              <div className="heading-view-all">
                <h5>Top Selling Medicine</h5>
                <Link to="/Medicines" className="view-all">
                  View All <img src={ic_arrow_right_green} alt="" />
                </Link>
              </div>

              <div className="doctors-card-wrapper row">
                {topSellingMedicines.map((item, i) => {
                  const imagePath = item?.product_image_path
                    ? item?.product_image_path
                    : null;
                  return (
                    <div className="col-md-3" key={item?.product_id}>
                      <div className="doctors-card">
                        <div className="img-section">
                          <span className="heart">
                            {item?.customer_favourite_id ? (
                              <img
                                src={ic_heart_filled}
                                alt=""
                                onClick={() => {
                                  // setlike1(!like1)
                                  addToFavourite(
                                    item?.product_id,
                                    item?.entity_type_id,
                                    "dislike",
                                    "pharmacy"
                                  );
                                }}
                              />
                            ) : (
                              <img
                                src={ic_heart_blank}
                                alt=""
                                onClick={() => {
                                  // setlike1(!like1)
                                  addToFavourite(
                                    item?.product_id,
                                    item?.entity_type_id,
                                    "like",
                                    "pharmacy"
                                  );
                                }}
                              />
                            )}
                          </span>
                          <img
                            src={imagePath ? imagePath : dummymedicene}
                            alt="Not found"
                            className="img-dr"
                            onError={(e) => (e.target.src = dummymedicene)}
                          />
                          {item?.customer_card_id ? (
                            <button
                              className="book-btn"
                              id="btn-added"
                              // onClick={() => setItemAdded(!itemAdded)}
                            >
                              <img src={ic_added_item} alt="" /> Item Added
                            </button>
                          ) : (
                            <button
                              className="book-btn"
                              onClick={() => {
                                // setItemAdded(!itemAdded)
                                addToBagCart(
                                  item?.product_id,
                                  item?.product_rate,
                                  item?.pharmacy_shop_id,
                                  item?.entity_type_id
                                );
                              }}
                            >
                              <img src={ic_bag_light} alt="" /> Add to Bag
                            </button>
                          )}
                        </div>

                        <div className="name-rating">
                          <div className="wrapper">
                            <div className="left">
                              <label htmlFor="" className="name">
                                {item?.product}
                                {/* Pet Supplements for Dog */}
                              </label>
                            </div>
                          </div>
                          <div className="price-and-rating">
                            <span className="cr-price">
                              ₹ {parseFloat(item?.product_rate).toFixed(2)}
                            </span>
                            {item?.avg_rating && (
                              <div className="right">
                                <span className="name">{item?.avg_rating}</span>
                                <img src={ic_rating_start} alt="" />
                              </div>
                            )}
                          </div>

                          {/* <span className='price'>
                          ₹ {parseFloat(item?.product_rate).toFixed(2)}
                        </span> */}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className='col-md-3'>
                <div className='doctors-card'>
                  <div className='img-section'>
                    <span className='heart'>
                      {like2 ? (
                        <img
                          src={ic_heart_filled}
                          alt=''
                          onClick={() => setlike2(!like2)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=''
                          onClick={() => setlike2(!like2)}
                        />
                      )}
                    </span>
                    <img src={dr_2} alt='' className='img-dr' />
                    {itemAdded ? (
                      <button
                        className='book-btn'
                        id='btn-added'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt='' /> Item Added
                      </button>
                    ) : (
                      <button
                        className='book-btn'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt='' /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className='name-rating'>
                    <div className='wrapper'>
                      <div className='left'>
                        <label htmlFor='' className='name'>
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className='right'>
                        <span className='name'>4.3</span>
                        <img src={ic_rating_start} alt='' />
                      </div>
                    </div>
                    <span className='cr-price'>₹40.00</span>
                    <span className='price'>₹50.00</span>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='doctors-card'>
                  <div className='img-section'>
                    <span className='heart'>
                      {like3 ? (
                        <img
                          src={ic_heart_filled}
                          alt=''
                          onClick={() => setlike3(!like3)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=''
                          onClick={() => setlike3(!like3)}
                        />
                      )}{' '}
                    </span>
                    <img src={dr_1} alt='' className='img-dr' />
                    {itemAdded ? (
                      <button
                        className='book-btn'
                        id='btn-added'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt='' /> Item Added
                      </button>
                    ) : (
                      <button
                        className='book-btn'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt='' /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className='name-rating'>
                    <div className='wrapper'>
                      <div className='left'>
                        <label htmlFor='' className='name'>
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className='right'>
                        <span className='name'>4.3</span>
                        <img src={ic_rating_start} alt='' />
                      </div>
                    </div>
                    <span className='cr-price'>₹40.00</span>
                    <span className='price'>₹50.00</span>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='doctors-card'>
                  <div className='img-section'>
                    <span className='heart'>
                      {like4 ? (
                        <img
                          src={ic_heart_filled}
                          alt=''
                          onClick={() => setlike4(!like4)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=''
                          onClick={() => setlike4(!like4)}
                        />
                      )}{' '}
                    </span>
                    <img src={dr_2} alt='' className='img-dr' />
                    {itemAdded ? (
                      <button
                        className='book-btn'
                        id='btn-added'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt='' /> Item Added
                      </button>
                    ) : (
                      <button
                        className='book-btn'
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt='' /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className='name-rating'>
                    <div className='wrapper'>
                      <div className='left'>
                        <label htmlFor='' className='name'>
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className='right'>
                        <span className='name'>4.3</span>
                        <img src={ic_rating_start} alt='' />
                      </div>
                    </div>
                    <span className='cr-price'>₹40.00</span>
                    <span className='price'>₹50.00</span>
                  </div>
                </div>
              </div> */}
              </div>
            </section>
          ) : null}
          {topSellingProducts && topSellingProducts.length > 0 && (
            <section className="top-doctors-wrapper top-selling-product">
              <div className="heading-view-all">
                <h5>Top Selling Products</h5>
                <Link to="/PetShops" className="view-all">
                  View All <img src={ic_arrow_right_green} alt="" />
                </Link>
              </div>

              <div className="doctors-card-wrapper row">
                {topSellingProducts &&
                  topSellingProducts.map((item, idx) => {
                    return (
                      <div className="col-md-3">
                        <div className="doctors-card">
                          <div className="img-section">
                            <span className="heart">
                              {item?.customer_favourite_id ? (
                                <img
                                  src={ic_heart_filled}
                                  alt=""
                                  onClick={() => {
                                    addPetFoodToFavourite(
                                      item?.product_id,
                                      item?.entity_type_id,
                                      "dislike"
                                    );
                                    // setlike1(!like1)
                                    // addPetFoodToFavourite = async (product_id, entity_type_id, actionType)
                                  }}
                                />
                              ) : (
                                <img
                                  src={ic_heart_blank}
                                  alt=""
                                  onClick={() => {
                                    addPetFoodToFavourite(
                                      item?.product_id,
                                      item?.entity_type_id,
                                      "like"
                                    );
                                    // setlike1(!like1)
                                    // addPetFoodToFavourite = async (product_id, product_id, actionType)
                                  }}
                                />
                              )}
                            </span>
                            <img
                              src={item?.product_image_path}
                              alt=""
                              className="img-dr"
                            />
                            {item?.customer_card_id ? (
                              <button
                                className="book-btn"
                                id="btn-added"
                                onClick={() => {
                                  // setItemAdded(!itemAdded);
                                }}
                              >
                                <img src={ic_added_item} alt="" /> Item Added
                              </button>
                            ) : (
                              <button
                                className="book-btn"
                                onClick={() => {
                                  // setItemAdded(!itemAdded)
                                  addToBagCart(
                                    item?.product_id,
                                    item?.product_rate,
                                    item?.pet_shop_id,
                                    item?.entity_type_id
                                  );
                                }}
                              >
                                <img src={ic_bag_light} alt="" /> Add to Bag
                              </button>
                            )}
                          </div>

                          <div className="name-rating">
                            <div className="wrapper">
                              <div className="left">
                                <label htmlFor="" className="name">
                                  {item?.product}
                                </label>
                              </div>
                              {item?.avg_rating && (
                                <div className="right">
                                  <span className="name">
                                    {item?.avg_rating}
                                  </span>
                                  <img src={ic_rating_start} alt="" />
                                </div>
                              )}
                            </div>
                            <span className="cr-price">
                              ₹{item?.product_rate}
                            </span>
                            {/* <span className="price">₹50.00</span> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="col-md-3">
                <div className="doctors-card">
                  <div className="img-section">
                    <span className="heart">
                      {like2 ? (
                        <img
                          src={ic_heart_filled}
                          alt=""
                          onClick={() => setlike2(!like2)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=""
                          onClick={() => setlike2(!like2)}
                        />
                      )}
                    </span>
                    <img src={pdct1} alt="" className="img-dr" />
                    {itemAdded ? (
                      <button
                        className="book-btn"
                        id="btn-added"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt="" /> Item Added
                      </button>
                    ) : (
                      <button
                        className="book-btn"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt="" /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className="name-rating">
                    <div className="wrapper">
                      <div className="left">
                        <label htmlFor="" className="name">
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className="right">
                        <span className="name">4.3</span>
                        <img src={ic_rating_start} alt="" />
                      </div>
                    </div>
                    <span className="cr-price">₹40.00</span>
                    <span className="price">₹50.00</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="doctors-card">
                  <div className="img-section">
                    <span className="heart">
                      {like3 ? (
                        <img
                          src={ic_heart_filled}
                          alt=""
                          onClick={() => setlike3(!like3)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=""
                          onClick={() => setlike3(!like3)}
                        />
                      )}{" "}
                    </span>
                    <img src={pdct1} alt="" className="img-dr" />
                    {itemAdded ? (
                      <button
                        className="book-btn"
                        id="btn-added"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt="" /> Item Added
                      </button>
                    ) : (
                      <button
                        className="book-btn"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt="" /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className="name-rating">
                    <div className="wrapper">
                      <div className="left">
                        <label htmlFor="" className="name">
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className="right">
                        <span className="name">4.3</span>
                        <img src={ic_rating_start} alt="" />
                      </div>
                    </div>
                    <span className="cr-price">₹40.00</span>
                    <span className="price">₹50.00</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="doctors-card">
                  <div className="img-section">
                    <span className="heart">
                      {like4 ? (
                        <img
                          src={ic_heart_filled}
                          alt=""
                          onClick={() => setlike4(!like4)}
                        />
                      ) : (
                        <img
                          src={ic_heart_blank}
                          alt=""
                          onClick={() => setlike4(!like4)}
                        />
                      )}
                    </span>
                    <img src={pdct1} alt="" className="img-dr" />
                    {itemAdded ? (
                      <button
                        className="book-btn"
                        id="btn-added"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_added_item} alt="" /> Item Added
                      </button>
                    ) : (
                      <button
                        className="book-btn"
                        onClick={() => setItemAdded(!itemAdded)}
                      >
                        <img src={ic_bag_light} alt="" /> Add to Bag
                      </button>
                    )}
                  </div>

                  <div className="name-rating">
                    <div className="wrapper">
                      <div className="left">
                        <label htmlFor="" className="name">
                          Pet Supplements for Dog
                        </label>
                      </div>
                      <div className="right">
                        <span className="name">4.3</span>
                        <img src={ic_rating_start} alt="" />
                      </div>
                    </div>
                    <span className="cr-price">₹40.00</span>
                    <span className="price">₹50.00</span>
                  </div>
                </div>
              </div> */}
              </div>
            </section>
          )}
        </div>
        {recentArticles && recentArticles.length ? (
          <div className="artical-wrapper">
            <div className="main-cards-wrapper">
              <div className="artical-main-wrapper">
                <div className="heading-view-all">
                  <h4>Read Our Articles</h4>
                  {/* <Link to="#" className="view-all">
                    View All <img src={ic_arrow_right_white} alt="" />
                  </Link> */}
                </div>
              </div>
              <div className="artical-card-wrapper row">
                {recentArticles.map((item, i) => {
                  const imagePath = item?.article_image_path
                    ? item?.article_image_path
                    : null;
                  return (
                    <div className="col-md-4" key={item?.article_id}>
                      <div className="articel-card">
                        <div className="img-section">
                          <img
                            src={imagePath ? imagePath : ""}
                            alt="Not found"
                            className="img-dr"
                          />
                          {/* <button className='book-btn'>
                            Read More <img src={ic_right_arrow_circle} alt='' />
                          </button> */}
                        </div>
                        <p className="articel-para">
                          {item?.article_title}
                          {/* How to keep your Animal clean and Healthy. */}
                        </p>
                        <p className="date-view">
                          {item?.date && dateAndMonthOnly(item?.date) && (
                            <span className="me-2">
                              {dateAndMonthOnly(item?.date)}
                              {/* 2nd July */}
                            </span>
                          )}
                          |{" "}
                          {item?.view_count ? (
                            <span className="ms-2">
                              {item?.view_count} views
                            </span>
                          ) : (
                            <span className="ms-2">0 views</span>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className='col-md-4'>
                      <div className='articel-card'>
                        <div className='img-section'>
                          <img src={dog_2} alt='' className='img-dr' />
                          <button className='book-btn'>
                            Read More <img src={ic_right_arrow_circle} alt='' />
                          </button>
                        </div>
                        <p className='articel-para'>
                          How to keep your Animal clean and Healthy.
                        </p>
                        <p className='date-view'>
                          <span className='me-2'>2nd July</span>|
                          <span className='ms-2'>125 views</span>
                        </p>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='articel-card'>
                        <div className='img-section'>
                          <img src={dog_1} alt='' className='img-dr' />
                          <button className='book-btn'>
                            Read More <img src={ic_right_arrow_circle} alt='' />
                          </button>
                        </div>
                        <p className='articel-para'>
                          How to keep your Animal clean and Healthy.
                        </p>
                        <p className='date-view'>
                          <span className='me-2'>2nd July</span>|
                          <span className='ms-2'>125 views</span>
                        </p>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
        ) : null}
        <div className="top-scroll-btn-main">
          <button onClick={topScroll} className="top-scroll-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="47"
              viewBox="0 0 46 47"
              fill="none"
            >
              <path
                d="M23.0007 4.25732C12.4207 4.25732 3.83398 12.844 3.83398 23.424C3.83398 34.004 12.4207 42.5907 23.0007 42.5907C33.5807 42.5907 42.1673 34.004 42.1673 23.424C42.1673 12.844 33.5807 4.25732 23.0007 4.25732ZM29.7665 23.4815C29.479 23.769 29.1148 23.9032 28.7507 23.9032C28.3865 23.9032 28.0223 23.769 27.7348 23.4815L24.4382 20.1848V30.1323C24.4382 30.9182 23.7865 31.5698 23.0007 31.5698C22.2148 31.5698 21.5632 30.9182 21.5632 30.1323V20.1848L18.2665 23.4815C17.7107 24.0373 16.7907 24.0373 16.2348 23.4815C15.9675 23.211 15.8176 22.846 15.8176 22.4657C15.8176 22.0853 15.9675 21.7203 16.2348 21.4498L21.9848 15.6998C22.5407 15.144 23.4606 15.144 24.0165 15.6998L29.7665 21.4498C30.3223 22.0057 30.3223 22.9257 29.7665 23.4815Z"
                fill="#1D7C7C"
              />
            </svg>
          </button>
        </div>
        <footer>
          <div className="footer-wrapper row">
            <div className="col-md-3 ">
              <div className="logo-section">
                <img src={logo} alt="" className="logo" />
                <p>Animals App, Animalsomatic clinic</p>
                <p>
                  Near Ravi Steel, Kamde, Ratu Road, Ranchi, Jharkhand, India
                  835222
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-section">
                <h6>Explore</h6>
                <ul>
                  <li>
                    <Link to="/DoctorsList">Doctor’s</Link>
                  </li>
                  <li>
                    <Link to="/PetShops">Pet Shop’s</Link>
                  </li>
                  <li>
                    <Link to="/Medicines">Pharmacist</Link>
                  </li>
                  <li>
                    <Link to="#">Articles</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-section">
                <h6>Need Help ?</h6>
                <ul>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/ReturnAnRefund">Returns & Refunds</Link>
                  </li>
                  <li>
                    <Link to="#">Track your order</Link>
                  </li>
                  <li>
                    <Link to="#">FAQ’s</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-section">
                <h6>About</h6>
                <ul>
                  <li>
                    <Link to="#">Company</Link>
                  </li>
                  <li>
                    <Link to="/TermsAndCondition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/ShippingPolicy">Shipping Policy</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="social-media-wrapper">
            <div className="left">
              <label htmlFor="" className="heading">
                Stay Connected
              </label>
              <div className="social-icons-wrapper">
                <Link
                  target="_blank"
                  to="https://play.google.com/store/apps/details?id=com.animal.customer"
                  className="same-style g"
                >
                  <img
                    // src={ic_google}
                    src={playstore}
                    alt=""
                  />
                </Link>

                {/**
                <Link to="#" className="same-style w">
                  <img src={ic_whatsapp} alt="" />
                </Link>
              */}
                {/**
                <Link to="#" className="same-style p">
                  <img src={ic_playstore} alt="" />
                </Link>
              */}
                {/**
            
              <Link to="#" className="same-style a">
                <img src={ic_appstore} alt="" />
              </Link>
            */}
              </div>
            </div>
            <div className="right">
              <label htmlFor="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M10.08 11.0944C10.13 10.7644 10.24 10.4744 10.38 10.2244C10.52 9.97437 10.72 9.76437 10.97 9.60437C11.21 9.45437 11.51 9.38438 11.88 9.37438C12.11 9.38438 12.32 9.42438 12.51 9.50438C12.71 9.59438 12.89 9.71438 13.03 9.86438C13.17 10.0144 13.28 10.1944 13.37 10.3944C13.46 10.5944 13.5 10.8144 13.51 11.0344H15.3C15.28 10.5644 15.19 10.1344 15.02 9.74438C14.85 9.35438 14.62 9.01438 14.32 8.73438C14.02 8.45437 13.66 8.23438 13.24 8.07438C12.82 7.91438 12.36 7.84438 11.85 7.84438C11.2 7.84438 10.63 7.95437 10.15 8.18437C9.67 8.41437 9.27 8.71438 8.95 9.10437C8.63 9.49437 8.39 9.94437 8.24 10.4644C8.09 10.9844 8 11.5244 8 12.1044V12.3744C8 12.9544 8.08 13.4944 8.23 14.0144C8.38 14.5344 8.62 14.9844 8.94 15.3644C9.26 15.7444 9.66 16.0544 10.14 16.2744C10.62 16.4944 11.19 16.6144 11.84 16.6144C12.31 16.6144 12.75 16.5344 13.16 16.3844C13.57 16.2344 13.93 16.0244 14.24 15.7544C14.55 15.4844 14.8 15.1744 14.98 14.8144C15.16 14.4544 15.27 14.0744 15.28 13.6644H13.49C13.48 13.8744 13.43 14.0644 13.34 14.2444C13.25 14.4244 13.13 14.5744 12.98 14.7044C12.83 14.8344 12.66 14.9344 12.46 15.0044C12.27 15.0744 12.07 15.0944 11.86 15.1044C11.5 15.0944 11.2 15.0244 10.97 14.8744C10.72 14.7144 10.52 14.5044 10.38 14.2544C10.24 14.0044 10.13 13.7044 10.08 13.3744C10.03 13.0444 10 12.7044 10 12.3744V12.1044C10 11.7544 10.03 11.4244 10.08 11.0944ZM12 2.23438C6.48 2.23438 2 6.71438 2 12.2344C2 17.7544 6.48 22.2344 12 22.2344C17.52 22.2344 22 17.7544 22 12.2344C22 6.71438 17.52 2.23438 12 2.23438ZM12 20.2344C7.59 20.2344 4 16.6444 4 12.2344C4 7.82438 7.59 4.23438 12 4.23438C16.41 4.23438 20 7.82438 20 12.2344C20 16.6444 16.41 20.2344 12 20.2344Z"
                    fill="#F2FBFA"
                  />
                </svg>
                {/* 2022 Animal App, Inc. */}
                {new Date().getFullYear()} A Venture By ANIMALSOMATIC HEALTH CLINIC PRIVATE LIMITED
              </label>
            </div>
          </div>
        </footer>
        <div className="modal-for-customer-details">
          <Modal
            show={openModal}
            // show={true}
            onHide={() => setOpenModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5>What should we call you?</h5>
              </Modal.Title>
              <img
                src={close}
                alt="close"
                onClick={() => setOpenModal(false)}
                style={{cursor : "pointer"}}
              />
            </Modal.Header>
            <Form
              noValidate
              validated={validated}
              onSubmit={updateCustomerProfile}
            >
              <Modal.Body>
                <div>
                  <section
                    className="wrapper-cards"
                    style={{ padding: "10px" }}
                  >
                    <div className="row">
                      <div
                        className="col-md-12 row"
                        style={{ width: "800px", marginBottom: "20px" }}
                      >
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              className="capital"
                              id="firstname"
                              type="text"
                              placeholder="Enter first name..."
                              value={updateCustomerPayload?.first_name}
                              required
                              name="first_name"
                              onChange={passwordHandlerr}
                            />
                            <Form.Control.Feedback type="invalid">
                              First name is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              className="capital"
                              id="lastName"
                              type="text"
                              placeholder="Enter last name"
                              value={updateCustomerPayload?.last_name}
                              required
                              name="last_name"
                              onChange={passwordHandlerr}
                            />
                            <Form.Control.Feedback type="invalid">
                              Last name is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-12 row" style={{ width: "800px" }}>
                        <div className="col-md-6 form-group">
                          <Form.Group className="">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control
                              id="contactNumber"
                              type="tel"
                              pattern="[6789][0-9]{9}"
                              placeholder="Enter contact number"
                              value={updateCustomerPayload?.phone_number}
                              required
                              name="phone_number"
                              onChange={(e) => {
                                if (validNumber.test(e.target.value)) {
                                  passwordHandlerr(e);
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter valid contact number
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="save-btn"
                  style={{
                    backgroundColor: "#1D7C7C",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 20px",
                    color: "#fff",
                  }}
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Home;
